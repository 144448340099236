<template>
  <form v-if="!preventBind" class="w-m-content financeiro-window"> <!--  @submit.prevent="save" -->
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Novo Proprietário</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <e-row mr>
        <e-col>
          <erp-s-field label="Nome">
            <erp-input autofocus placeholder="Nome do proprietário" v-model="model.nome"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Data de Início">
            <erp-input placeholder="dd/mm/aaaa" v-model="model.inicio" v-mask="'##/##/####'"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Data de Fim">
            <erp-input placeholder="dd/mm/aaaa" v-model="model.fim" v-mask="'##/##/####'"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <textarea class="sl-textarea" placeholder="Motivo de assumir e/ou entregar a propriedade" v-model="model.motivo" rows="4"></textarea>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
      </div>
    </div>
  </form>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {datePtToEn} from "@/utils/date"
import {find, updateProprietario, newProprietario} from "@/domain/bem/services/historicoProprietarios"

const mockProprietario = {
  id: null,
  nome: null,
  inicio: null,
  fim: null,
  motivo: null,
  bem: null
}

export default {
  name: 'BemProprietarioWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  components: {
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    // UTooltip,
  },
  props: ['id', 'bem', 'options', 'router'],
  data() {
    return {
      loading: false,
      success: false,
      preventBind: true,
      model: this.getMock()
    }
  },
  mounted() {
    if (this.isEditing) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    isEditing() {
      return this.id || (this.model && this.model.id)
    }
  },
  methods: {
    getMock() {
      let mock = JSON.parse(JSON.stringify(mockProprietario))
      return mock
    },
    load() {
      this.loading = true
      find(this.bem.id, this.isEditing)
          .then(response => {
            console.log(response)
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))
      if (data.inicio) {
        data.inicio = datePtToEn(data.inicio)
      }
      if (data.fim) {
        data.fim = datePtToEn(data.fim)
      }
      data.bem = this.bem
      const relacoes = ['bem']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      return data
    },
    prepareToEdit(data) {
      const datas = ['inicio', 'fim']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      return data
    },
    save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newProprietario
      if (this.model.id) {
        method = updateProprietario
      }
      method(this.bem.id, data)
          .then(response => {
            this.loading = false
            console.log(response)
            const isEditing = this.isEditing
            this.model.id = response.data.id
            // this.$uloc.window.close(this.$root.wid)
            if (isEditing) {
              this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
              this.success = true
              if (type === 'andEdit') {
                this.dg()
              } else {
                this.$uloc.notify({
                  color: 'positive',
                  message: `Proprietário com ID #${response.data.id} alterado.`,
                  position: 'bottom-right',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
            } else {
              this.$uloc.window.emit(this.$root.wid, 'created', response.data)
              this.$uloc.notify({
                color: 'black',
                message: `Novo Proprietário com ID #${response.data.id} criado.`,
                position: 'bottom-right',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
              this.$uloc.window.close(this.$root.wid)
            }
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
